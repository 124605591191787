// extracted by mini-css-extract-plugin
export var dapphero = "landingpage-module--dapphero--1ftZ7";
export var appanimations = "landingpage-module--appanimations--2LoxF";
export var reactanimation = "landingpage-module--reactanimation--34Ay3";
export var lottie = "landingpage-module--lottie--3bT0G";
export var empty = "landingpage-module--empty--2JZ-u";
export var placeholder = "landingpage-module--placeholder--2AZZA";
export var feature = "landingpage-module--feature--3-TFa";
export var secondfeature = "landingpage-module--secondfeature--2SD_G";
export var serveranimation = "landingpage-module--serveranimation--1VZRp";
export var animation = "landingpage-module--animation--2hyve";
export var commentsvg = "landingpage-module--commentsvg--18M82";
export var landinghero = "landingpage-module--landinghero--1qvYU";
export var dlandingheroimage = "landingpage-module--dlandingheroimage--8KDkh";
export var heroicons = "landingpage-module--heroicons--3Jr0V";
export var icons = "landingpage-module--icons--NgZs6";
export var testimonial1 = "landingpage-module--testimonial1--1C0gK";
export var products = "landingpage-module--products--1l5Gm";
export var leadmagnet = "landingpage-module--leadmagnet--31yLQ";
export var freecourse = "landingpage-module--freecourse--1x6V0";
export var leadmagnetimg = "landingpage-module--leadmagnetimg--oUZyy";
export var sectionanimation = "landingpage-module--sectionanimation--1OIlf";
export var inner = "landingpage-module--inner--2mAsJ";
export var parallaxsnap = "landingpage-module--parallaxsnap--2XsDY";
export var refform = "landingpage-module--refform--2ci9z";
export var referralinput = "landingpage-module--referralinput--vb-Hb";
export var visuals = "landingpage-module--visuals--3SfTb";
export var paragraph = "landingpage-module--paragraph--3pI37";
export var questionimg = "landingpage-module--questionimg--17HEL";
export var casestudy = "landingpage-module--casestudy--bxyH3";
export var specificuser = "landingpage-module--specificuser--saxWg";
export var browser = "landingpage-module--browser--2Q4Af";
export var dlanding = "landingpage-module--dlanding--242xY";
export var emailimage = "landingpage-module--emailimage--1OJzJ";
export var featureselected = "landingpage-module--featureselected--1N6tF";
export var mfeatures = "landingpage-module--mfeatures--3l-w1";
export var mtotal = "landingpage-module--mtotal--1ZEAc";
export var dfeatureupper = "landingpage-module--dfeatureupper--38-43";
export var mfeatureupper = "landingpage-module--mfeatureupper--1fmyT";
export var loadingform = "landingpage-module--loadingform--1-hEu";
export var submittedform = "landingpage-module--submittedform--2v2U_";
export var landingThree = "landingpage-module--landingThree--24dDG";
export var svganim = "landingpage-module--svganim--3bGdH";
export var howinner = "landingpage-module--howinner--99Dv4";
export var smile = "landingpage-module--smile--2uAaY";
export var slowsvg = "landingpage-module--slowsvg---p501";
export var fast = "landingpage-module--fast--13nMa";
export var slow = "landingpage-module--slow--1FhT9";
export var emailauto = "landingpage-module--emailauto--2g7Xt";
export var mlandingheroimage = "landingpage-module--mlandingheroimage--1UFvI";
export var mblock = "landingpage-module--mblock--2DjIX";
export var odditem = "landingpage-module--odditem--12uYr";
export var colorbg1 = "landingpage-module--colorbg1--3kTr2";
export var oddcontent = "landingpage-module--oddcontent--24iJm";
export var oddimage = "landingpage-module--oddimage--4M4BS";
export var evenitem = "landingpage-module--evenitem--3huc-";
export var evencontent = "landingpage-module--evencontent--25y7i";
export var evenimage = "landingpage-module--evenimage--1PZak";
export var colorbg2 = "landingpage-module--colorbg2--1GIIn";
export var border = "landingpage-module--border--3t7pr";
export var bordergrow = "landingpage-module--bordergrow--2Kq8Y";
export var howcontainer = "landingpage-module--howcontainer--36uUe";
export var contenttext = "landingpage-module--contenttext--3QNhh";